.revenue{
    text-align: center;
    font-size: 16;
    font-weight: 700;
    margin-top: 24px;
    margin-bottom: 40px;
}
.revenue-number{
    font-size: 40;
}
.revenue-banner{
    font-weight: 700;
    font-size: 24;
}
.revenue-label{
    font-size: 16;
    font-weight: 500;
    color: rgb(168, 168, 168);
}
.revenue-title{
    font-size: 14;
    font-weight: 700;
    text-align: center;
}
