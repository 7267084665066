/**/
$secondary: #ff4713;
$primary: #0a2240;
$grey-3: #acaeb0;
body,
#root > div {
    background-color: #f3f2f1;
}

.slidersArea {
    position: relative;
    @apply transition-all;
    @apply duration-500;
/*    max-height:352px;*/
    #editModeMsg {
        top: 50%;
        left: 0;
        right: 0;
        max-width: 400px;
        margin: auto;
        transform: translateY(-50%);
        z-index: 999;
        pointer-events: visible;
    }
    &:after {
        opacity: 0;
        content: '';
        @apply transition-all;
        @apply duration-500;
    }
    &.editMode {
        @apply pointer-events-none;
        &:after {
            opacity: 1;
            border-radius: 0.25rem;
            display: block;
            position: absolute;
            z-index: 5;
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background-color: rgba(255, 255, 255, 0.75);
        }
    }
}
.widgetGrid {
    .bg-white.rounded {
        &:after {
            opacity: 0;
            content: '';
            @apply transition-all;
            @apply duration-500;
        }
    }
    &.editMode {
        @apply mt-4;
        .bg-white.rounded {
            position: relative;
            pointer-events: none;
            &:after {
                opacity: 1;
                border-radius: 0.25rem;
                display: block;
                position: absolute;
                z-index: 5;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                background-color: rgba(255, 255, 255, 0.5);
                border: 2px dashed #ebebeb;
            }
        }
    }
    .layoutActions {
        pointer-events: visible;
    }
}
.tile-height {
    height: 320px;
    overflow-y: auto;
    &-430 {
        height: 430px;
        overflow-y: auto;
    }
    &-500 {
        height: 500px !important;
    }
    &-600 {
        height: 600px;
    }
    &-800 {
        height: 800px;
    }
}

.fixed-header-height {
    // padding-top: 62px !important;
    padding-top: 85px !important;
}

#fixed-header {
    @apply fixed;
    @apply bg-white;
    @apply px-4;
    z-index: 1000;
    // height: 50px;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid #ebebeb; //matching white ui border header
    .header-logo {
        height:49px;
        width: auto;
    }
    .sub-logo{
        height: 20px;
        width: auto;
    }
}
.square-thumbnail-bg {
    height: 100%;
    width: 100%;
}
.noSlickDots {
    .slick-dots {
        display: none !important;
    }
}
button {
    cursor: pointer;

    .ui-box {
        width: auto;
        svg {
            font-size: 1.4rem;
        }
    }
    &.text-xs {
        span {
            font-size: 0.75rem;
        }
        svg {
            font-size: 1.4rem;
        }
    }
    &.afterIcon {
        .ui-box {
            margin-left: 2px;
        }
    }
    &.iconOnly {
        min-width: 2rem;
    }
    &.textButton {
        @apply text-grey-3;
        &.headingButton {
            .ui-button__content {
                @apply font-bold;
                @apply text-black;
                @apply text-xs;
                @apply uppercase;
            }
        }
    }
    &.primaryButton {
        background-color: $primary;
        &:hover {
            background-color: lighten($primary, 10%);
        }
    }
    &.secondaryButton {
        background-color: $secondary;
        &:hover {
            background-color: lighten($secondary, 10%);
        }
    }
}
input {
    &.ui-box.ui-input__input {
        @apply bg-off-white;
    }
}
ul.ui-menu {
    &.widgetMenu {
        height: 32px;
        border-bottom-width: 1px !important;
        li.ui-menu__itemwrapper {
            box-shadow: none !important;
            height: 30px;
            min-width: auto;
            a {
                height: 30px;
                padding: 0 12px !important;
                span {
                    margin-top: 0 !important;
                    @apply uppercase;
                    @apply text-xs;
                    @apply text-center;
                    color: inherit !important;
                    margin-bottom: 0 !important;
                    line-height: 30px;
                }
            }
        }
    }
}
div {
    &.ui-datepicker {
        > .ui-button,
        > .ui-box {
            height: 24px;
        }
        input.ui-box {
            @apply text-grey-3;
            @apply uppercase;
            @apply text-xs;
            width: 100%;
            text-align: left;
        }
    }
    &.ui-textarea {
        @apply bg-off-white;
    }
    &.ui-dropdown {
        .ui-dropdown__clear-indicator svg {
            @apply fill-grey-3;
        }
        .ui-dropdown__toggle-indicator svg {
            @apply fill-grey-3;
        }
        div.ui-dropdown__container {
            @apply bg-off-white;
            ul.listbox {
                position: absolute;
            }
        }
        &.invertedDropdown div.ui-dropdown__container {
            background-color: transparent;
            &:hover {
                background-color: transparent;
            }
        }
        .ui-dropdown__selected-items {
            width: 100%;
        }
        &.widgetDropdown {
            .ui-dropdown__selected-items {
                justify-content: right;
            }
            div button {
                min-height: 24px;
                height: 24px;
            }
        }
        &.searchDropdown {
            padding-left: 30px;
            @apply flex;
            @apply items-center;
            .ui-dropdown__container {
                @apply rounded-none;
                &:focus-within {
                    border-bottom: 0;
                }
                .ui-input__input {
                    padding-left: 0;
                    &:focus {
                        border-bottom: 0;
                    }
                }
            }
        }
        div button {
            padding-left: 6px;
            padding-right: 6px;
            min-width: auto;
            max-width: initial;
            width: 100%;
            span {
                @apply text-grey-3;
                @apply uppercase;
                @apply text-xs;
                width: 100%;
                text-align: left;
            }
        }
        &.w-90 {
            div {
                &.ui-dropdown__container {
                    width: 90px;
                }
            }
        }
        &.w-100 {
            div {
                &.ui-dropdown__container {
                    width: 100px;
                }
            }
        }
        &.w-150 {
            div {
                &.ui-dropdown__container {
                    width: 150px;
                }
            }
        }
        &.w-200 {
            div {
                &.ui-dropdown__container {
                    width: 200px;
                }
            }
        }
    }
}

.hide {
    display: none!important;
}

/* Responsive */
.tabletHide {
    display: block;
}
.mobileHide {
    display: block;
}
.mobileShow {
    display: none;
}
@media (max-width: 1024px), screen and (max-device-width: 1024px) {
    .tabletHide {
        display: none !important;
    }
}

@media (max-width: 767px), screen and (max-device-width: 767px) {
    .fixed-header-height {
        padding-top: 88px!important;
    }
    #fixed-header {
        flex-direction: column;
        height:88px;
    }
    .mobileShow {
        display: block!important;
    }
    .mobileHide {
        display: none !important;
    }
}
