.search-input {
    input::placeholder {
        @apply text-grey-3;
    }
    input {
        height:32px;
    }
}

@media (max-width: 1024px), screen and (max-device-width: 1024px) {
    button.filtersButton {
        min-width: auto !important;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;

        > .ui-box:first-child {
            margin-right: 0 !important;
        }
        > span {
            display: none;
        }
    }
}
