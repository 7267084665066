.w-full-btn {
    width: 100%;
}

.dropdown-postion {
    position: absolute !important;
    top: 34px;
    background: white;
    z-index: 999;
    max-height: 75vh;
    overflow-y: auto;
    box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    & > li {
        padding-left: 0;
        padding-right: 0;
    }
}
