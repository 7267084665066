mgt-file {
    --file-type-icon-size: 28px;
    --file-border: none;
    --file-box-shadow: none;
    --file-background-color: #ffffff;
    --font-family: 'Segoe UI';
    --font-size: 0.75rem;
    --font-weight: 400;
    --text-transform: none;
    --color: rgb(37, 36, 35);
    --line3-font-size: 0.625rem;
    --line3-font-weight: 600;
    --line3-color: #acaeb0;
    --line3-text-transform: uppercase;
}
