/* Border overrides */
.rbc-month-view .rbc-month-row .rbc-event {
    max-height: 2rem;
}
.rbc-calendar {
    position: relative;
}
.rbc-month-view {
    border-top: 0;
    border-left: 0;
    border-right: 0;
}
.rbc-month-row {
    border-left-width: 1px;
    border-right-width: 1px;
    @apply border-primary-lightest;
}

.rbc-month-view,
.rbc-header + .rbc-header,
.rbc-day-slot .rbc-time-slot,
.rbc-day-bg + .rbc-day-bg {
    @apply border-primary-lightest;
}
.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
    border: 0;
}
/* Header text */
.rbc-agenda-table,
.rbc-time-header-cell,
.rbc-month-header {
    .rbc-header {
        @apply text-xxs;
        @apply text-grey-3;
        @apply uppercase;
    }
}

.rbc-agenda-table {
    @apply text-xs;
}
.rbc-label {
    @apply text-xxs;
}
.rbc-date-cell {
    text-align: left !important;
    padding-left: 4px;
}

/*Month view*/
.rbc-month-header {
    .rbc-header {
        border-left: 0;
        border-right: 0;
        @apply border-b;
        @apply border-primary-lightest;
    }
}
.rbc-off-range-bg {
    background-color: #f2f2f2;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
    padding: 2px 3px;
}
.rbc-day-slot .rbc-event-content,
.rbc-ellipsis,
.rbc-show-more,
.rbc-row-segment .rbc-event-content,
.rbc-event-label,
.rbc-date-cell {
    font-size: 10px;
}
