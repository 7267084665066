.slick-list {
    height: 100% !important;
}
.celebSlider .slick-list {
    /*    min-height: 120px;*/
}
.valuesSlider .slick-list {
    height: 160px!important;
}
.slick-slide.slick-active.slick-current {
    z-index: 10;
}
.newsSlider .slick-list {
    /*  min-height: 240px;*/
}
.slick-slide {
    height: auto;
}
.slick-track:after {
    content: '';
    display: block;
    clear: both;
}
.slick-dots {
    bottom: 10px;
    right: 10px;
    //width: 33.3333%;
    //Had to change this to 20% since it was covering the Read more button
    width: 20%;
    text-align: right;
    li {
        margin: 0;
    }
}
.newsArticleImage {
    height: 336px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 316px;
}
.valuesImage {
    height: 160px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 140px;
}
.newsArticleText {
    height: 336px;
    min-height: 316px;
    overflow-y: auto;
}
.newsHeight {
    /*    max-height: 360px;*/
}

.valuesText {
    height: 160px;
    overflow-y: auto;
    position: relative;
}
@media (max-width: 1400px), screen and (max-device-width: 1400px) {
    .newsHeight {
        /*        max-height: 420px;*/
    }
}
@media (max-width: 1024px), screen and (max-device-width: 1024px) {
    .valuesImage {
        .valuesTitle {
            .text-base {
            }
        }
    }
}
@media (max-width: 767px), screen and (max-device-width: 767px) {
    .newsArticleText {
        z-index: 10;
        .text-secondary,
        .text-primary,
        div {
            color: #fff !important;
        }
    }
    .newsArticleText {
        .blurb {
            font-size: 0.875rem !important;
        }
        .newsTitle {
            font-size: 1.25rem !important;
        }
    }
    .newsArticleImage {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        height: 280px;
        &:after {
            content: '';
            background-color: rgba(0, 0, 0, 0.4);
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            @apply rounded;
            display: block;
        }
    }
}
