mgt-person {
    padding: 0;
    --font-weight: 300;
    --font-size: 0.75rem;
    --avatar-size: 20px;
}
.videoIcon {
    svg {
        fill: #fff;
        @apply mr-2;
        width: 14px;
    }
}
mgt-person {
    /*8px;
    --avatar-border: 0;
    --avatar-border-radius: 50%;
    --avatar-cursor: default;

    --initials-color: white;
    --initials-background-color: magenta;

    --presence-background-color: #ffffff;
    --presence-icon-color: #ffffff;

    --font-family: 'Segoe UI';
    --font-size: 14px;
    --font-weight: 500;
    --color: black;
    --text-transform: none;

    --line2-font-size: 12px;
    --line2-font-weight: 400;
    --line2-color: black;
    --line2-text-transform: none;

    --line3-font-size: 12px;
    --line3-font-weight: 400;
    --line3-color: black;
    --line3-text-transform: none;

    --details-spacing: 12px;*/
}
