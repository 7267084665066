div.planner-task-title {
    .ui-accordion__title__content-wrapper {
        div:first-of-type {
            background: none;
            width: 100%;
            margin-right: 0;
        }
    }
}

div.announce {
    background: white;
    width: 170px;
    height: 50px;
    box-shadow: 3px 2px 11px #a9a6a6;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    top: 121%;
    right: 17px;
    font-weight: 400;
    z-index: 9999999;
}

div.ui-datepicker {
    display: flex !important;
}

.text-color {
    span.ui-button__content {
        color: rgb(24, 24, 24) !important;
    }
}

.text-date-color {
    input.ui-input__input {
        color: rgb(24, 24, 24) !important;
    }
}

div.ui-accordion__title.planner-task-title {
    padding: 0 !important;
}

.small-hide {
    display: inline-block;
}
@media (max-width: 1020px), screen and (max-device-width: 1020px) {
    .small-hide {
        display: none;
    }
    /*
    .add-button {
        min-width: 40px !important;
        max-width: 40px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }*/
}
