.ui-menu {
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom-width: 0 !important;
    &::-webkit-scrollbar {
        width: 4px !important;
    }
    li.ui-menu__itemwrapper {
        padding-left: 0;
        padding-right: 0;
        min-width: 100px;
        height: 100%;
        a.ui-menu__item {
            height: 100%;
            padding-bottom: 0.3rem;
            padding-left: 0.3rem;
            padding-right: 0.3rem;
            .ui-menu__itemcontent {
                @apply text-secondary;
                @apply font-bold;
            }
            &[aria-selected='true'] {
                @apply border-b-secondary;
                border-bottom-width: 2px !important;
            }
            &[aria-selected='false']:hover {
                border-bottom-width: 0 !important;
            }
        }
    }
}
