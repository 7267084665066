.force-hidden {
    display: none !important;
}

.preview-image {
    background: #dbdbdb;
    border-radius: 8px;
    width: 17rem;
    height: 7rem;
    & > img {
      object-fit: contain;
    }
}
