.three-dot-menu {
    position: absolute !important;
    right: -10%;
    z-index: 9999;
    top: 120%;
    background-color: white;
    box-shadow: 1px 4px 13px #d3d3d3;
    border-radius: 7%;
    border: 1px solid #f3f2f2
}

.notice {
    transform: translate(6%, -186%);
    padding: 7px;
    position: relative;
    &::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #000000;
        position: absolute;
        top: 90%;
        right: 37px;
    }
}
